import React, { useEffect, useState } from "react"
import { AiOutlineClose } from "react-icons/ai"
import { GiHamburgerMenu } from "react-icons/gi"
import { Link, useStaticQuery, graphql } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

import "../styles/header.scss"
import logo from "../images/logo.svg"

const Header = props => {
  const [drop, setDrop] = useState(false)
  const [open, setOpen] = useState(false)
  const [icon, setIcon] = useState()

  useEffect(() => {
    !drop
      ? setIcon(() => (
          <div className="menu" id="menu">
            <GiHamburgerMenu onClick={onClick} />
          </div>
        ))
      : setIcon(() => (
          <div className="menu" id="menu">
            <AiOutlineClose onClick={onClick} />
          </div>
        ))
  }, [drop])

  const handleButtonClick = () => {
    setOpen(!open)
  }

  const onClick = () => {
    setDrop(!drop)
    setOpen(!open)
  }

  const handleDropDownClick = node => {
    scrollTo(`#${node.frontmatter.id}`)
    setOpen(false)
    setDrop(false)
  }

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/services/" } }
        sort: { order: ASC, fields: frontmatter___order }
      ) {
        edges {
          node {
            ...IndexServiceQuery
          }
        }
      }
    }
  `)

  const services = data.allMarkdownRemark.edges.filter(node => node !== null)

  return (
    <header>
      <nav className="container">
        <div className="toggle">
          <Link>
            <div className="logo" onClick={() => scrollTo("#page-top")}>
              <img src={logo} alt="logo" width="100" />
              {!drop ? <span>Omnicode Solutions</span> : ""}
            </div>
          </Link>
          {icon}
        </div>

        <Link>
          <div className="logo_large" onClick={() => scrollTo("#page-top")}>
            <img src={logo} alt="logo" width="50" />
            <span>Omnicode Solutions</span>
          </div>
        </Link>

        <ul id="UL" className={drop ? "active" : ""}>
          {!drop && props.page === "Home" && (
            <Link>
              <li className="button" onClick={() => scrollTo("#page-top")}>
                Home
              </li>
            </Link>
          )}

          {!drop && props.page !== "Home" && (
            <Link to="/">
              <li className="button">Home</li>
            </Link>
          )}

          <div className="dropdown">
            {!drop ? (
              <Link>
                <li className="button" onClick={handleButtonClick}>
                  Services
                </li>
              </Link>
            ) : (
              ""
            )}

            {open && props.page === "Home" && (
              <div class="dropdown-content">
                <ul>
                  {services.map(({ node }) => {
                    return (
                      <li
                        onClick={() => handleDropDownClick(node)}
                        key={node.frontmatter.order}
                      >
                        {node.frontmatter.description}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}

            {open && props.page !== "Home" && (
              <div class="dropdown-content">
                <ul>
                  {services.map(({ node }) => {
                    return (
                      <Link
                        to={node.frontmatter.slug}
                        key={node.frontmatter.order}
                      >
                        <li>{node.frontmatter.description}</li>
                      </Link>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
        </ul>
      </nav>
    </header>
  )
}

export default Header
