/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, imageName }) {
  let imageUrl = ""

  const data = useStaticQuery(graphql`
    query SeoQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
      allFile(filter: { relativePath: { regex: "images/" } }) {
        nodes {
          extension
          publicURL
          name
        }
      }
    }
  `)

  const metaDescription = description || data.site.siteMetadata.description
  const metaUrl = `https://omnicode.solutions`
  const twitterCard = imageName ? `product` : `summary`
  const images = data.allFile.nodes.filter(node => {
    return node.extension === "png"
  })

  images.forEach(image => {
    if (
      (title === "Omnicode Solutions" && image.name === "logo-big") ||
      image.name === imageName
    ) {
      imageUrl = image.publicURL
    }
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          name: `twitter:card`,
          content: twitterCard,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: metaUrl,
        },
        {
          property: `twitter:image`,
          content: imageUrl,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  imageName: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  imageName: PropTypes.string,
}

export default SEO
