import React from "react"
import talk from "../images/talk.svg"
import { graphql, useStaticQuery } from "gatsby"

export default function Contact(props) {
  const data = useStaticQuery(graphql`
    query ContactPageQuery {
      markdownRemark(fileAbsolutePath: { regex: "/content/contact/" }) {
        frontmatter {
          description
          title
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "content/services/" } }
        sort: { order: ASC, fields: frontmatter___order }
      ) {
        nodes {
          frontmatter {
            description
            id
            order
          }
        }
      }
    }
  `)

  return (
    <section id={props.id || ""}>
      <div className="contact">
        <div className="container">
          <div className="talk-image">
            <img src={talk} alt="talk" />
            <h1>{data.markdownRemark.frontmatter.title}</h1>
          </div>
          <p>{data.markdownRemark.frontmatter.description}</p>
          <form
            className="contact-form"
            action="https://getform.io/f/b2b49e0d-ec7d-44af-b9a0-b8567014e680"
            method="POST"
          >
            <input
              type="hidden"
              id="captchaResponse"
              name="g-recaptcha-response"
            ></input>
            <input
              type="text"
              placeholder="Name"
              required
              name="name"
              className="field"
            />
            <input
              type="tel"
              placeholder="Contact Number (optional)"
              name="telephone"
              className="field"
            />
            <select
              name="service"
              className="field"
              defaultValue={props.selected}
              required
            >
              <option value="other">General subject</option>
              {data.allMarkdownRemark.nodes.map(node => {
                return (
                  <option
                    value={node.frontmatter.id}
                    key={node.frontmatter.order}
                  >
                    {node.frontmatter.description}
                  </option>
                )
              })}
            </select>
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              className="field"
            />
            <textarea
              rows="10"
              cols="25"
              placeholder="Your Message"
              name="message"
              className="field"
            />
            <button>Submit</button>
          </form>
        </div>
      </div>
    </section>
  )
}
