import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

import "../styles/footer.scss"
import Contact from "./contact"
import stamp from "../images/logo.inline.svg"
import linkedin from "../images/icon-linkedin.svg"
import facebook from "../images/icon-facebook.svg"
import twitter from "../images/icon-twitter.svg"
import gmail from "../images/icon-gmail.svg"
import github from "../images/icon-github.svg"
import instagram from "../images/icon-instagram.svg"

const Footer = props => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/services/" } }
        sort: { order: ASC, fields: frontmatter___order }
      ) {
        edges {
          node {
            ...IndexServiceQuery
          }
        }
      }
    }
  `)

  const services = data.allMarkdownRemark.edges.filter(node => node !== null)

  return (
    <>
      <Contact
        page={props.page}
        id={props.id || ""}
        selected={props.selected}
      />
      <footer>
        <div className="footer">
          <div className="container">
            <div className="footer-content">
              <div className="pages">
                <Link to="/">
                  <h2>Our Services:</h2>
                </Link>
                {services.map(({ node }) => {
                  return (
                    <Link
                      to={node.frontmatter.slug}
                      key={node.frontmatter.order}
                    >
                      <p>{node.frontmatter.description}</p>
                    </Link>
                  )
                })}
              </div>
              <div className="address">
                <h2>Address:</h2>
                <p>Al Salvador, 1057. Sl 911.</p>
                <p>Salvador</p>
                <p>41820-790</p>
                <p>Brazil</p>
              </div>
              <div className="legal">
                <h2>Legal:</h2>
                <Link to="/legal/terms-of-service">
                  <p>Terms of Service</p>
                </Link>
                <Link to="/legal/privacy-policy">
                  <p>Privacy Policy</p>
                </Link>
              </div>
            </div>
            <div className="svg">
              <div className="hr">
                <hr />
              </div>
              <img src={stamp} alt="Omnicode Logo" width="50" />
              <div className="hr">
                <hr />
              </div>
            </div>
            <div className="linked">
              {" "}
              <a
                href="https://www.linkedin.com/company/omnicode-solutions/"
                target="_blank"
                rel="noreferrer"
                title="LinkedIn"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
              <a
                href="https://www.facebook.com/omnicode.solutions/"
                target="_blank"
                rel="noreferrer"
                title="LinkedIn"
              >
                <img src={facebook} alt="facebook" />
              </a>
              <a
                href="https://twitter.com/omnicode_brasil"
                target="_blank"
                rel="noreferrer"
                title="Twitter"
              >
                <img src={twitter} alt="twitter" />
              </a>
              <a
                href="https://instagram.com/omnicode.solutions"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <img src={instagram} alt="instagram" />
              </a>
              <Link to="/contact">
                <img src={gmail} alt="gmail" />
              </Link>
              <a
                href="https://github.com/OmnicodeSolutions/"
                target="_blank"
                rel="noreferrer"
                title="Github"
              >
                <img src={github} alt="github" />
              </a>
            </div>
            <div className="copy-right">
              <p>Copyright 2020, Omnicode Solutions. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
